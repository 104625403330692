import {Fragment} from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import {ErrorBoundary} from 'mui-core';
import 'slick-carousel/slick/slick.css';
import {styled} from '@mui/material/styles';
import 'slick-carousel/slick/slick-theme.css';
import {Box, useTheme, useMediaQuery} from '@mui/material';
import {
  carouselLeftArrow,
  carouselRightArrow,
  carouselLeftArrowDark,
  carouselRightArrowDark,
} from 'assets/images';

const StyledCarouselContainer = styled(Box)(
  ({fullWidth, theme, disablePebbleShadow}) => ({
    '& .slick-slider': {
      maxWidth: fullWidth ? 'initial' : 1400,
      [theme.breakpoints.only('sm')]: {
        margin: '12px',
      },
      [theme.breakpoints.only('xs')]: {
        margin: '0px',
      },
      '& .slick-list': {
        '& .slick-track': {
          gap: '32px',
          display: 'flex',
          padding: '16px 0px',
          margin: '0px 0px 0px -24px',
          [theme.breakpoints.down('md')]: {
            gap: '24px',
            margin: '0px 0px 0px -24px',
          },
        },
      },
      '& .slick-dots': {
        bottom: '0px',
        position: 'relative',
      },
      [`& a`]: {
        color: theme.palette.secondary.dark,
      },
      [`& .slick-dots button:before`]: {
        display: 'none',
      },

      [`& .slick-dots li`]: {
        width: 'initial',
        height: 8,
      },
      [`& .slick-dots button`]: {
        width: 32,
        height: 8,
        padding: 0,
        borderRadius: 5,
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow:
          !disablePebbleShadow &&
          `0px 0px 8px ${theme.palette.grayText.main} !important`,
        [theme.breakpoints.down('sm')]: {
          width: 28,
        },
      },
      [`& .slick-dots li.slick-active button`]: {
        backgroundColor: theme.palette.primary.main,
      },
      '& .sliderBtn': {
        img: {
          width: '30px !important',
          height: '30px !important',
        },
        [theme.breakpoints.only('sm')]: {
          img: {
            width: '24px !important',
            height: '24px !important',
          },
        },
      },
    },
  }),
);

const Carousel = ({
  fullWidth,
  speed = 5000,
  content = [],
  slidesToScroll,
  showDots = true,
  autoplay = false,
  showArrows = false,
  carouselType = 'card',
  transitionSpeed = 500,
  singleViewSlide = false,
  disablePebbleShadow = true,
  slidesToShow = singleViewSlide ? 1 : slidesToShow ? slidesToShow : 4,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.only('md'));

  let slidesToScrollLocal = 1;
  if (slidesToScroll) {
    slidesToScrollLocal = slidesToScroll;
  } else {
    if (isMobile) {
      slidesToScrollLocal = 1;
    } else if (isTablet) {
      slidesToScrollLocal = 2;
    } else if (isMedium) {
      slidesToScrollLocal = 3;
    } else {
      slidesToScrollLocal = slidesToShow;
    }
  }

  function PreviousBtn(props) {
    const {onClick, currentSlide, className, style} = props;
    const leftImgSrc =
      theme.palette.mode === 'dark' ? carouselLeftArrowDark : carouselLeftArrow;

    return (
      <>
        {currentSlide !== 0 && (
          <Box className='sliderBtn prevBtn' onClick={onClick}>
            <img
              className={className}
              src={leftImgSrc}
              style={style}
              alt='Prev'
            />
          </Box>
        )}
      </>
    );
  }

  function NextBtn(props) {
    const {onClick, slideCount, currentSlide, className, style} = props;
    const rightImgSrc =
      theme.palette.mode === 'dark'
        ? carouselRightArrowDark
        : carouselRightArrow;

    return (
      <>
        {currentSlide !== slideCount - slidesToShow && (
          <Box className='sliderBtn nextBtn' onClick={onClick}>
            <img
              className={className}
              src={rightImgSrc}
              style={style}
              alt='Next'
            />
          </Box>
        )}
      </>
    );
  }

  const settings = {
    lazyLoad: true,
    autoplay: autoplay,
    autoplaySpeed: speed,
    speed: transitionSpeed,
    nextArrow: <NextBtn />,
    prevArrow: <PreviousBtn />,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScrollLocal,
    arrows: showArrows === false ? false : !isMobile,
    infinite: content?.length > slidesToShow ? true : false,
    dots: showDots ? showDots : showDots === false ? false : isMobile,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: singleViewSlide
            ? 1
            : carouselType === 'img'
            ? content?.length > 3
              ? 3
              : content?.length
            : 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: singleViewSlide
            ? 1
            : carouselType === 'img'
            ? content?.length > 2
              ? 2
              : content?.length
            : 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    content &&
    Array.isArray(content) &&
    content?.length > 0 && (
      <StyledCarouselContainer
        fullWidth={fullWidth}
        disablePebbleShadow={disablePebbleShadow}>
        <ErrorBoundary nameOfComponent='core-carousel'>
          <Slider
            {...settings}
            // afterChange={idx => console.log('change', idx)}
          >
            {content.map((slide, idx) => (
              <Fragment key={`slide-${idx}`}>{slide}</Fragment>
            ))}
          </Slider>
        </ErrorBoundary>
      </StyledCarouselContainer>
    )
  );
};

Carousel.propTypes = {
  speed: PropTypes.number,
  showDots: PropTypes.bool,
  autoplay: PropTypes.bool,
  fullWidth: PropTypes.bool,
  showArrows: PropTypes.bool,
  singleViewSlide: PropTypes.bool,
  slidesToScroll: PropTypes.number,
  transitionSpeed: PropTypes.number,
  disablePebbleShadow: PropTypes.bool,
  content: PropTypes.array.isRequired,
  slidesToShow: PropTypes.number || PropTypes.object,
};

export default Carousel;
