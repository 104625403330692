import * as TYPES from './constants';
import {API} from 'config';

import {
  requestJobBoardsDetails,
  recieveJobBoardsDetails,
  //   clearJobBoardsDetails,
  requestJobBoardsDetailsError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.JOB_BOARDS_DETAILS_REQUEST:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.JOB_BOARDS_DETAILS_SUCCESS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.CLEAR_JOB_BOARDS_DETAILS:
      return {
        request: false,
        error: false,
      };
    case TYPES.JOB_BOARDS_DETAILS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const fetchJobBoardsDetails = (id, jobType, instName, job_type) => (
  dispatch,
  getState,
) => {
  dispatch(requestJobBoardsDetails());
  try {
    let endPoint =
      jobType === 'local'
        ? instName || job_type
          ? `${API.srm.opportunity}?type=${
              job_type ? job_type : 'job'
            }&title=${encodeURIComponent(
              id,
            )}&institute_name=${encodeURIComponent(instName)}`
          : `${API.srm.opportunity}?job_id=${encodeURIComponent(id)}`
        : `${API.srm.allJobs}`;
    const apiCall =
      jobType === 'local'
        ? services.fetchRecords(endPoint, null, true)
        : services.createUpdateRecord(null, endPoint, {job_id: id}, 'POST');
    return apiCall.then(res => {
      if (res) {
        dispatch(recieveJobBoardsDetails(res[0] ? res[0] : res));
        return;
      }
      dispatch(requestJobBoardsDetailsError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestJobBoardsDetailsError(err));
  }
};
