import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {NavLink as Link, useHistory} from 'react-router-dom';
import {
  Box,
  Menu,
  Grow,
  Stack,
  AppBar,
  Button,
  Switch,
  Avatar,
  Drawer,
  Divider,
  Toolbar,
  Skeleton,
  Typography,
  IconButton,
  Accordion,
  ListItemIcon,
  useMediaQuery,
  buttonClasses,
  AccordionSummary,
  AccordionDetails,
  MenuItem as DropDownMenuItem,
  Slide,
  Paper,
} from '@mui/material';
import {
  DarkMode,
  Dashboard,
  ExpandLess,
  ExpandMore,
  SearchOutlined,
  PowerSettingsNew,
  Menu as MenuIcon,
  SettingsOutlined,
} from '@mui/icons-material';
import {isString} from 'lodash';
import {MenuBar} from 'mui-core';
import {ignoreList} from 'config';
import SearchBar, {SearchContainer} from './SearchBar';
import PartnersMenu from './PartnersMenu';
import {appLogo, appLogoWhite} from 'assets/images';
import {styled, useTheme} from '@mui/material/styles';
import {openLoginScreen, logout} from 'redux/modules/auth';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import {useInstance, useAuth, useUser, useThemeMode} from 'core/hooks';
import {themeColorMode, getLogo, getProfilePhoto} from 'mui-core/utils';

const drawerWidth = 240;

const StyledProfileButton = styled(Button)(({mobile}) => {
  if (mobile)
    return {
      minWidth: 'initial',
      width: 38,
      height: 38,
      [`.${buttonClasses.startIcon}`]: {
        margin: 0,
      },
    };
  else return {};
});

const ThemeSwitch = styled(Switch)(({theme}) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const StyledAppBar = styled(AppBar)(({theme, menuBarOnHide, movedUp}) => ({
  height: 'initial',
  minHeight: 'initial',
  background: 'initial',
  color: theme.palette.text.primary,
  backgroundColor: themeColorMode(
    theme,
    theme.palette.background.paper,
    theme.palette.darkGray.main,
  ),
  filter:
    !menuBarOnHide || !movedUp
      ? 'none'
      : theme.palette.mode === 'light'
      ? `drop-shadow(0px 2px 3px rgba(123, 96, 223, 0.4))`
      : 'none',
  boxShadow:
    !menuBarOnHide || !movedUp
      ? 'none'
      : theme.palette.mode === 'light'
      ? '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
      : `0px 1px 0px 0px ${theme.palette.divider}`,
}));

const Root = styled('div')(({theme}) => ({
  width: '100%',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  '& > :not(style) ~ :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

const MenuItem = ({
  Icon,
  link,
  label,
  color,
  onClick,
  variant,
  external,
  children,
  smallScreen,
}) => {
  const theme = useTheme();
  const getButtonProps = () => {
    const props = {};
    props.exact = true;
    if (onClick) props.onClick = onClick;
    if (link && !external) {
      props.component = Link;
      props.to = link;
    } else if (external) {
      props.href = link;
      props.target = '_blank';
    }
    if (color) props.color = color;
    return props;
  };

  const renderButton = (buttonLabel, Icon, buttonProps) => (
    <Button
      color='primary'
      startIcon={
        Icon ? (
          <Icon
            sx={{
              color:
                theme.palette.mode === 'light'
                  ? theme.palette.grayText.main
                  : 'white',
            }}
          />
        ) : null
      }
      variant={variant || 'text'}
      sx={{
        ml: 2,
        letterSpacing: '0.4px',
        color: theme =>
          color
            ? color
            : theme.palette.mode === 'light'
            ? theme.palette.primary.dark
            : 'white',
      }}
      onClick={onClick}
      {...buttonProps}>
      {buttonLabel}
    </Button>
  );

  return (
    <>
      {children ? (
        <Stack width='100%'>
          <Accordion
            elevation={0}
            sx={{
              background: themeColorMode(
                theme,
                'text.primary',
                theme.palette.darkGray.main,
              ),
            }}>
            <AccordionSummary
              id='panel1a-header'
              expandIcon={<ExpandMore />}
              aria-controls='panel1a-content'
              sx={{
                '& .MuiAccordionSummary-content.Mui-expanded': {
                  margin: 0,
                },
                '&.Mui-expanded': {
                  minHeight: '48px',
                },
              }}>
              <Typography
                variant='bodyL'
                sx={{
                  color:
                    theme.palette.mode === 'light'
                      ? theme.palette.primary.dark
                      : 'white',
                }}>
                {label}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                background: themeColorMode(
                  theme,
                  '#f2f2f2',
                  theme.palette.dialogDarkBG.main,
                ),
              }}>
              <Stack direction='column' alignItems='flex-start'>
                {children.map(({label, icon, key, path}) => {
                  const childProps = getButtonProps();
                  if (link && !external) {
                    childProps.component = Link;
                    childProps.to = `/opportunities${path}`;
                  }
                  return renderButton(label, icon, childProps);
                })}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      ) : (
        renderButton(label, Icon, getButtonProps())
      )}
    </>
  );
};

export const AvatarDropdown = ({
  open,
  link,
  Icon,
  onClick,
  itemName,
  children,
}) => {
  const nonLinkItem = itemName === 'Theme Switch' || itemName === 'Logout';
  return (
    <Grow in={open} timeout={500}>
      <DropDownMenuItem
        onClick={onClick}
        to={!nonLinkItem && link}
        component={nonLinkItem ? 'li' : Link}>
        <ListItemIcon disablePadding sx={{minWidth: 43}}>
          <Icon />
        </ListItemIcon>
        {itemName}
        {children}
      </DropDownMenuItem>
    </Grow>
  );
};

function HideOnScroll(props) {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {props.children}
    </Slide>
  );
}

const AppHeader = ({
  window,
  movedUp,
  MainMenu,
  menuBarOnHide,
  defaultMenuSelectedKey,
}) => {
  const theme = useTheme();
  const appBarRef = useRef();
  const [token] = useAuth();
  const userData = useUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [themeMode, setThemeMode] = useThemeMode();
  const [mobileOpen, setMobileOpen] = useState(false);
  const {location: {pathname = ''} = {}} = useHistory();
  const {data: instanceData, isCriminalJustice} = useInstance();
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
  const trigger = useScrollTrigger();
  const triggerShadow = useScrollTrigger({
    threshold: 650,
    disableHysteresis: true,
  });

  const open = Boolean(anchorEl);
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const LOGO =
    (instanceData?.institution_id && getLogo(instanceData?.logo_cloudinary)) ||
    (theme?.palette?.mode === 'dark' ? appLogoWhite : appLogo);

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openLogin = useCallback((screen = 'signin', navigateTo) => {
    dispatch(
      openLoginScreen({
        screen,
        callback: auth => {
          if (navigateTo && isString(navigateTo)) {
            history.push(navigateTo);
          }
        },
        redirect: false,
      }),
    );
  });

  const toggleMobileSwipableSearch = state => {
    setMobileSearchOpen(state);
  };

  const logoText = useMemo(() => {
    const {first_name, last_name} = userData?.data || {};
    let text = first_name ? first_name : '';
    if (last_name) text = text + ' ' + last_name;
    if (text) {
      text = text.toUpperCase();
      const arr = text.split(' ');
      let txt = arr[0] && arr[0][0] ? arr[0][0] : '';
      if (arr[1] && arr[1][0]) txt = txt + arr[1][0];
      return txt;
    }
    return '';
  }, [userData]);

  // Logo
  const GoEducateLogo = (paddingLeft = 8) => {
    return (
      <Link to='/'>
        <img
          src={LOGO}
          alt='goeducate-logo'
          width={instanceData?.institution_id ? 'auto' : 160}
          height={instanceData?.institution_id ? 58 : smallScreen ? 'auto' : 58}
          style={{
            ...(instanceData?.institution_id
              ? {
                  padding: smallScreen
                    ? `8px 0px 4px ${paddingLeft}px`
                    : '6px 0px 5px 0px',
                }
              : {
                  padding: smallScreen ? `8px 0px 4px ${paddingLeft}px` : 0,
                }),
          }}
        />
      </Link>
    );
  };

  const userMenuItems = [
    {
      Icon: Dashboard,
      link: '/dashboard',
      onClick: handleClose,
      itemName: 'Dashboard',
    },
    {
      itemName: 'Settings',
      onClick: handleClose,
      Icon: SettingsOutlined,
      link: '/dashboard/settings',
    },
    {
      Icon: DarkMode,
      itemName: 'Theme Switch',
      onClick: () => setThemeMode(themeMode === 'dark' ? 'light' : 'dark'),
      children: (
        <ThemeSwitch checked={themeMode === 'dark'} size='small' sx={{ml: 2}} />
      ),
    },
    {
      itemName: 'Logout',
      Icon: PowerSettingsNew,
      onClick: () => {
        handleClose();
        dispatch(logout());
      },
    },
  ];

  const filteredUserMenuItems =
    userMenuItems?.filter(
      item => !(isCriminalJustice && item?.itemName === 'Settings'),
    ) || [];

  const userMenu = (
    <>
      <Menu
        open={open}
        id='account-menu'
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            mt: 1.5,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}>
        {filteredUserMenuItems.map(
          ({Icon, link, itemName, onClick, children}) => (
            <>
              {itemName === 'Logout' && <Divider />}
              <AvatarDropdown
                open={open}
                Icon={Icon}
                link={link}
                onClick={onClick}
                itemName={itemName}>
                {children}
              </AvatarDropdown>
            </>
          ),
        )}
      </Menu>
    </>
  );

  const userItem = () => {
    const clickProps = {};
    clickProps.onClick = handleClick;
    return (
      <>
        {userData.request && (
          <Stack direction='row' alignItems='center'>
            <Skeleton variant='circular'>
              <Avatar />
            </Skeleton>
            <Skeleton width={100} sx={{ml: 1}}>
              <Typography>.</Typography>
            </Skeleton>
          </Stack>
        )}
        {!userData.request && (
          <Box>
            <StyledProfileButton
              size='small'
              mobile={mobile}
              rounded={mobile}
              sx={{ml: 2, px: 1}}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup='true'
              startIcon={
                <Avatar
                  alt='avatar-logo'
                  children={userData?.data?.photo ? null : logoText}
                  src={
                    userData?.data?.photo
                      ? getProfilePhoto(userData.data.photo)
                      : null
                  }
                  sx={{
                    width: 32,
                    height: 32,
                    background:
                      theme.palette.mode === 'light'
                        ? theme.palette.darkGray.main
                        : theme.palette.darkGray.contrastText,
                  }}
                />
              }
              endIcon={open ? <ExpandLess /> : <ExpandMore />}
              aria-expanded={open ? 'true' : undefined}
              {...clickProps}>
              {!mobile && (
                <Typography
                  variant='subtitle2'
                  color={
                    'text.primary'
                  }>{`${userData?.data?.first_name} ${userData?.data?.last_name}`}</Typography>
              )}
            </StyledProfileButton>
          </Box>
        )}
        {userMenu}
      </>
    );
  };

  const navItems = () => {
    const drawerLayout = (name, array) => (
      <>
        {smallScreen && (
          <Root>
            <Divider sx={{my: 1}} textAlign='left'>
              {name}
            </Divider>
          </Root>
        )}
        {array &&
          Array.isArray(array) &&
          array?.map(item => {
            return (
              <MenuItem
                key={item?.key}
                Icon={item?.icon}
                label={item?.label}
                external={item?.external}
                children={item?.children}
                link={item?.path || item?.link}
                onClick={() => setMobileOpen(false)}
              />
            );
          })}
      </>
    );
    return (
      <>
        {/* MainMenu eg: Home, Careers etc.. */}
        {smallScreen && drawerLayout('Pages', MainMenu)}
      </>
    );
  };

  useEffect(() => {
    setMobileSearchOpen(false);
  }, [MainMenu]);

  return (
    <>
      {/* <HideOnScroll>
        <AppBar
          position='fixed'
          enableColorOnDark
          movedUp={movedUp}
          menuBarOnHide={menuBarOnHide}>
          <PartnersMenu />
        </AppBar>
      </HideOnScroll> */}
      <StyledAppBar
        position='fixed'
        ref={appBarRef}
        enableColorOnDark
        sx={{
          transform: trigger ? 'translateY(-50px)' : 'translateY(0px)',
          transition: '0.1s all cubic-bezier(.4,0,.6,1) 80ms',
          boxShadow: triggerShadow
            ? '0px 1px 14px 4px #00000021, 0px 1px 3px 1px #00000021'
            : 'none',
        }}
        movedUp={movedUp}
        menuBarOnHide={menuBarOnHide}>
        <PartnersMenu isCriminalJustice={isCriminalJustice} />
        {/* <PartnersMenu
            window={window}
            movedUp={movedUp}
            menuBarOnHide={menuBarOnHide}
          /> */}
        <Toolbar
          sx={{
            // py: '18px',
            position: 'relative',
            zIndex: 33,
            backgroundColor: 'inherit',
          }}
          alignItems='center'>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerToggle}
            sx={{display: {md: 'none'}}}>
            <MenuIcon />
          </IconButton>
          {GoEducateLogo()}
          <Stack
            pl={3}
            flexGrow={1}
            direction='row'
            justifyContent='start'
            width={smallScreen ? 'auto' : '50%'}>
            {!smallScreen && (
              // <SearchBar mobile={mobile} />
              <MenuBar
                movedUp={movedUp}
                MainMenu={MainMenu}
                menuBarOnHide={menuBarOnHide}
                defaultMenuSelectedKey={defaultMenuSelectedKey}
              />
            )}
          </Stack>
          {!smallScreen ? navItems() : null}
          {!ignoreList.includes(pathname) && (
            <>
              <IconButton
                sx={{ml: 1}}
                edge='start'
                color='inherit'
                aria-label='open drawer'
                onClick={() => toggleMobileSwipableSearch(!mobileSearchOpen)}>
                <SearchOutlined fontSize='15' />
              </IconButton>
              {token ? (
                userItem()
              ) : (
                <>
                  <Button
                    size='small'
                    variant='contained'
                    onClick={() => openLogin('signup')}
                    sx={{ml: 2, minWidth: smallScreen ? 'auto' : '110px'}}>
                    Sign Up
                  </Button>
                  {!smallScreen && !isCriminalJustice && (
                    <Button
                      size='small'
                      variant='outlined'
                      sx={{ml: 2, minWidth: '110px'}}
                      onClick={() => openLogin('signin')}>
                      Log In
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </Toolbar>
        {!ignoreList.includes(pathname) && (
          <SearchContainer
            appBarRef={appBarRef}
            toggleMobileSwipableSearch={toggleMobileSwipableSearch}
            mobileSearchOpen={mobileSearchOpen}>
            <SearchBar
              mobile={smallScreen}
              mobileSearchOpen={mobileSearchOpen}
              toggleMobileSwipableSearch={toggleMobileSwipableSearch}
            />
          </SearchContainer>
        )}
      </StyledAppBar>
      <Box component='nav'>
        <Drawer
          open={mobileOpen}
          container={container}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}>
          <Stack>
            {GoEducateLogo(16)}
            <Stack alignItems='flex-start'>
              {smallScreen && navItems()}
              {!isCriminalJustice && !token && (
                <>
                  <Root>
                    <Divider sx={{my: 2}} textAlign='left'>
                      Get Started
                    </Divider>
                  </Root>
                  <Button
                    sx={{ml: 2}}
                    variant='outlined'
                    onClick={() => openLogin('signin')}>
                    Log In
                  </Button>
                </>
              )}
            </Stack>
          </Stack>
        </Drawer>
      </Box>
    </>
    // </header>
  );
};

export default AppHeader;
