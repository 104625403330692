import {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
//Components
import {Box} from '@mui/material';
import {LayoutContainer} from 'mui-core';
import QualifyingProgramsList from './components';
import FilterForm from './components/FiltersForm/FilterForm';
// redux
import {
  getPaths,
  clearPathSearch,
  fetchPathsSearchWithFilters,
  fetchPathFilterOptions,
} from 'redux/modules/search';
//utils
import {queryStringParse} from 'core/utils';
import {explorePrograms} from 'data/explorePrograms.json';

const {
  qualifyingPrograms: {tabsData, sortOptionData, searchPlaceholder},
} = explorePrograms;

const tabKeys = [
  tabsData?.all?.key,
  tabsData?.college?.key,
  tabsData?.cte?.key,
  tabsData?.adult?.key,
];

const QualifyingPrograms = ({
  title,
  banner,
  noResults,
  cipCode = '',
  careerAreaList,
  showSort = true,
  globalSearch = '',
  showSearch = true,
  showDivider = true,
  applyRouting = true,
  disableBGcolor = false,
  // clusterName = '',
  // fixedScrollHeight,
  // occupationName = '',
  // businessPartnerName = '',
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {activeView = ''} = useParams();
  const programs = useSelector(getPaths);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeViewState, setActiveViewState] = useState(activeView);
  const {
    data: programsData,
    pathsSearchRequest,
    filterOptions,
    filterOptionsRequest,
  } = programs;
  const {
    location: {search},
  } = history;
  const {type: typeParam = 'all'} = queryStringParse(search); // defaults to all areas except when redirecting from home screen
  const durationOptions =
    filterOptions && filterOptions?.duration_list
      ? filterOptions && filterOptions?.duration_list
      : programsData?.duration_list
      ? programsData?.duration_list
      : [];
  const connectedInstitutions = programsData?.connected_institutions
    ? programsData?.connected_institutions
    : [];
  const awardTypeOptions =
    filterOptions && filterOptions?.award_type_list
      ? filterOptions?.award_type_list
      : programsData?.award_type_list
      ? programsData?.award_type_list
      : [];
  const programsList = programsData?.path_details
    ? programsData?.path_details
    : [];
  const recordsCount = programsList?.length || 0;
  const programsTotalCount = programsData?.page_details?.total_count || 0;
  const searchKey =
    cipCode ||
    globalSearch ||
    // clusterName ||
    // occupationName ||
    // businessPartnerName ||
    '';

  useEffect(() => {
    setActiveViewState(activeView);
  }, [activeView]);
  const category = useMemo(() => {
    if (globalSearch) {
      return 'gsearch';
    } else if (cipCode) {
      return 'cip_code';
      // } else if (clusterName) {
      //   return 'cluster_name';
      // } else if (occupationName) {
      // return 'occupation_name';
      // } else if (businessPartnerName) {
      // return 'company_name';
    } else {
      return '';
    }
  }, [
    cipCode,
    globalSearch,
    // clusterName,
    // occupationName,
    // businessPartnerName,
  ]);

  const sortOptions = useMemo(
    () => [
      {
        title: sortOptionData?.relevancy,
        value: category === 'gsearch' ? 'relevant' : 'date_desc',
      },
      {
        title: sortOptionData?.a2z,
        value: 'title_asc',
      },
      {
        title: sortOptionData?.z2a,
        value: 'title_desc',
      },
    ],
    [category],
  );

  const defaultFilters = useMemo(
    () => ({
      page: 1,
      type: typeParam,
      ins_group: activeViewState,
      sort_by: sortOptions[0].value,
    }),
    [typeParam, activeViewState], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    let newFilters = {...defaultFilters};
    if (category && searchKey) {
      newFilters[category] = searchKey;
    }
    dispatch(clearPathSearch());
    if (!tabKeys?.includes(activeView) && !cipCode) {
      history.push(`/programs`);
    }
    refetchData(newFilters);
    return () => {
      dispatch(clearPathSearch());
    };
  }, [searchKey, activeViewState]); // eslint-disable-line react-hooks/exhaustive-deps

  const refetchData = useCallback(filtersObj => {
    setFilters(filtersObj);
    dispatch(fetchPathsSearchWithFilters(filtersObj));
  });

  const tabs = useMemo(() => {
    return [
      {
        key: tabsData?.all?.key,
        tabName: tabsData?.all?.name,
        heading: tabsData?.all?.heading,
        subHeading: tabsData?.all?.subHeading,
        component: (
          <QualifyingProgramsList
            title={title}
            filters={filters}
            programs={programs}
            category={category}
            noResults={noResults}
            searchKey={searchKey}
            refetchData={refetchData}
            // fixedScrollHeight={fixedScrollHeight}
          />
        ),
      },
      {
        key: tabsData?.college?.key,
        tabName: tabsData?.college?.name,
        heading: tabsData?.college?.heading,
        subHeading: tabsData?.college?.subHeading,
        component: (
          <QualifyingProgramsList
            title={title}
            filters={filters}
            programs={programs}
            category={category}
            noResults={noResults}
            searchKey={searchKey}
            refetchData={refetchData}
            // fixedScrollHeight={fixedScrollHeight
          />
        ),
      },
      {
        key: tabsData?.cte?.key,
        tabName: tabsData?.cte?.name,
        heading: tabsData?.cte?.heading,
        subHeading: tabsData?.cte?.subHeading,
        component: (
          <QualifyingProgramsList
            title={title}
            filters={filters}
            programs={programs}
            category={category}
            noResults={noResults}
            searchKey={searchKey}
            refetchData={refetchData}
            // fixedScrollHeight={fixedScrollHeight
          />
        ),
      },
      {
        key: tabsData?.adult?.key,
        tabName: tabsData?.adult?.name,
        heading: tabsData?.adult?.heading,
        subHeading: tabsData?.adult?.subHeading,
        component: (
          <QualifyingProgramsList
            title={title}
            filters={filters}
            programs={programs}
            category={category}
            noResults={noResults}
            searchKey={searchKey}
            refetchData={refetchData}
            // fixedScrollHeight={fixedScrollHeight
          />
        ),
      },
    ];
  }, [activeView, programs]); // eslint-disable-line react-hooks/exhaustive-deps

  const onTabChange = (e, path) => {
    if (applyRouting) {
      if (tabKeys.includes(path) && path !== '') {
        history.push(`/programs/${path}`);
      } else {
        history.push(`/programs`);
      }
    } else {
      setActiveViewState(path);
    }
  };

  const onFinish = useCallback(
    (values, closeDrawer = true, fetchOnlyFilters) => {
      let filterApply = false;
      if (values) {
        const filterKeys = Object.keys(values);
        filterKeys.forEach(key => {
          if (values[key] || values[key] === '') {
            filterApply = true;
          }
        });
        if (filterApply) {
          if (fetchOnlyFilters) {
            const _filters = {...filters, ...values};
            setFilters(_filters);
            dispatch(fetchPathFilterOptions(_filters));
          } else {
            refetchData({...filters, ...values, page: 1});
          }
          closeDrawer && onDrawerClose();
        }
      }
    },
    [filters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onSortChange = useCallback(value => {
    onFinish({...filters, sort_by: value, page: 1});
  });

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const onDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const onFilterReset = resetObj => {
    const Obj = {...filters, ...resetObj};
    refetchData(Obj);
    onDrawerClose();
  };

  const dataSet = {
    data: programsList,
    request: pathsSearchRequest,
  };

  return (
    <LayoutContainer
      banner={banner}
      dataSet={dataSet}
      typeOfUi='fullPage'
      showSort={showSort}
      showFilterBtn={true}
      showSearch={showSearch}
      showDivider={showDivider}
      nameOfComponent='programs'
      activeTab={activeViewState}
      disableBGcolor={disableBGcolor}
      tabArr={globalSearch || cipCode ? [] : tabs}
      searchResultProps={{
        recordsCount: recordsCount,
        totalCount: programsTotalCount,
      }}
      onChange={(e, index) => onTabChange(e, index)}
      searchProps={{
        filters,
        onFinish,
        onDrawerOpen,
        onFilterReset,
        name: category || 'path_title',
        searchPlaceholder: searchPlaceholder,
      }}
      sortProps={{
        onSortChange,
        sortArr: sortOptions,
        sort: filters?.sort_by,
      }}
      filterForm={
        <Box key={activeView}>
          <FilterForm
            filters={filters}
            onFinish={onFinish}
            isDrawerOpen={isDrawerOpen}
            onDrawerClose={onDrawerClose}
            onFilterReset={onFilterReset}
            careerAreaList={careerAreaList}
            durationOptions={durationOptions}
            awardTypeOptions={awardTypeOptions}
            filterOptionsRequest={filterOptionsRequest}
            connectedInstitutions={connectedInstitutions}
          />
        </Box>
      }
      title={
        globalSearch || cipCode
          ? ''
          : tabs[tabKeys.indexOf(activeView)]?.heading
      }
      subTitle={
        globalSearch || cipCode
          ? ''
          : tabs[tabKeys.indexOf(activeView)]?.subHeading
      }>
      {(globalSearch || cipCode) && (
        <QualifyingProgramsList
          title={title}
          filters={filters}
          programs={programs}
          category={category}
          noResults={noResults}
          searchKey={searchKey}
          refetchData={refetchData}
          // fixedScrollHeight={fixedScrollHeight
        />
      )}
    </LayoutContainer>
  );
};

QualifyingPrograms.propTypes = {
  title: PropTypes.string,
  cipCode: PropTypes.string,
  noResults: PropTypes.string,
  globalSearch: PropTypes.string,
  // clusterName: PropTypes.string,
  // occupationName: PropTypes.string,
  // fixedScrollHeight: PropTypes.number,
  // businessPartnerName: PropTypes.string,
};

export default QualifyingPrograms;
