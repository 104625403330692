import {useEffect} from 'react';
import {useUser} from 'core/hooks';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {createField} from 'mui-core/Form';
import {useDispatch, useSelector} from 'react-redux';
import {Button, ContentBox, RequestErrorLoader} from 'mui-core';
import {
  getUserTypeList,
  fetchUserTypelist,
  updateUserProfile,
} from 'redux/modules/profile';
import {
  Box,
  Select,
  MenuItem,
  Typography,
  formHelperTextClasses,
} from '@mui/material';
// data
import SettingsJSON from 'data/dashboard-settings.json';

const {
  userType: {title, selectlabel},
  personalDetails: {successMsg, errorMsg, saveBtn},
} = SettingsJSON;

const UserType = ({}) => {
  // hooks
  const form = useForm();
  const {reset} = form;
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {data: userData, request: userDataRequest} = useUser();
  const {data: userTypeList = []} = useSelector(getUserTypeList) || {};
  const {user_type} = userData || {};
  const {handleSubmit} = form;

  const selectedUserType = userTypeList?.find(
    userType => userType.user_type_uuid === user_type,
  );

  const defaultUserTypeValue = selectedUserType
    ? selectedUserType.user_type_uuid
    : '';

  // effect
  useEffect(() => {
    (!userTypeList ||
      (Array.isArray(userTypeList) && userTypeList?.length === 0)) &&
      dispatch(fetchUserTypelist());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    reset({user_type: user_type}); // Reset form with user type
  }, [reset, user_type]);

  const onSubmit = ({user_type = ''}) => {
    const formData = {user_type};
    dispatch(
      updateUserProfile(formData, success => {
        if (success) enqueueSnackbar(successMsg, {variant: 'success'});
        else enqueueSnackbar(errorMsg, {variant: 'error'});
      }),
    );
  };

  return (
    <ContentBox
      elevation={0}
      style={{
        position: 'relative',
      }}
      sx={{p: 4, [`& .${formHelperTextClasses.root}`]: {marginLeft: 0}}}>
      <RequestErrorLoader
        hideEmpty
        fullScreen
        body={{request: userDataRequest}}
      />
      <Box mb={4}>
        <Typography variant='h4' color='text.primary'>
          {title}
        </Typography>
      </Box>
      {createField('user_type', {
        form,
        label: selectlabel,
        defaultValue: defaultUserTypeValue,
        rules: {
          required: [true, errorMsg.noUserType || ''],
        },
      })(
        <Select
          id='user_type'
          variant='outlined'
          label={selectlabel}
          sx={{maxWidth: 530, mb: 4}}
          style={{textAlign: 'left'}}>
          {Array.isArray(userTypeList) &&
            userTypeList.map(item => (
              <MenuItem key={item.user_type_uuid} value={item.user_type_uuid}>
                {item.user_type}
              </MenuItem>
            ))}
        </Select>,
      )}
      <Button size='large' variant='outlined' onClick={handleSubmit(onSubmit)}>
        {saveBtn}
      </Button>
    </ContentBox>
  );
};

export default UserType;
